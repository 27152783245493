
/* .heading {
    font-size: 3rem;
    font-family: Archia webfont, sans-serif;
} */

.heading > div {
    overflow: hidden;
}

.heading > div > p {
  /* width: max-content; */
  white-space: normal;
  margin: 0;
  line-height: 117%;
}

.slide-up {
  --delay: 0s; /* Default delay */
  -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) var(--delay) both;
  animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) var(--delay) both;
}



@-webkit-keyframes slide-up {
  0% {
    transform: translateY(195px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(195px);
  }
  100% {
    transform: translateY(0);
  }
}
